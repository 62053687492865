import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as ArrowForward } from "images/arrowForward.svg";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative text-center`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center xs:px-10 lg:px-0`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 md:text-left`;

const Subheading = tw(SubheadingBase)`xs:text-left md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl xs:text-left md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-4 flex flex-col md:flex-row xs:items-start  md:items-start`;
const StepIcon = tw.div`mt-5 xs:hidden lg:block`;
const StepNumber = tw.div`font-semibold text-xl leading-none text-gray-400 mt-4`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold xs:text-left`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium xs:text-left`;
const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`bg-brand-500 hover:bg-brand-700 hover:text-white mt-12 text-sm inline-block mx-auto md:mx-0 rounded-full text-white p-2 px-5`,
  props.buttonRounded && tw`rounded-full`
]);
const IconContainer = tw.i``;
export default ({
  subheading = null,
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
  primaryButtonText = "Let's Talk",
  primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, How can i GROW my Digital Content Business with Aurum",
  buttonRounded = false,
}) => {console.log(steps);
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Register",
      description: "Create an account with us using Google or Facebook."
    },
    {
      heading: "Download",
      description: "Browse and Download the template that you like from the marketplace."
    },
    {
      heading: "Run",
      description: "Follow the instructions to setup and customize the template to your needs."
    }
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  {step.mainIcon ==""? <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>:<StepIcon>{step.mainIcon}</StepIcon>}
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
            {primaryButtonText && 
              <LinkButton buttonRounded={buttonRounded} className="exploreBtn" as="a" href={primaryButtonUrl}>
                {primaryButtonText} <IconContainer><ArrowForward className="btnArrow" /></IconContainer>
              </LinkButton>
            }
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
